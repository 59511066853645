import React from 'react';
import {Layout,Typography} from "antd"

export default () => {
    const {Text} = Typography
    return (
        <>
            <Layout.Footer style={{minHeight : "10vh"}}>
                <Text type="secondary">Global health intelligence {new Date().getFullYear()}</Text>
            </Layout.Footer>
        </>
    );
}

