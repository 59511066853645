import React from 'react';
import {List} from "antd";
import {PhoneFilled} from "@ant-design/icons";
import countryCodes from '../../lib/countrycodes.json'
import {useTranslation} from "react-i18next"

const TelephoneList = ({country,telephones = [],areaCode = ''}) => {
    const {t} = useTranslation()
    return(
        <List>
                {telephones['telephones']?.map((phone:any,index) => {
                let fullNumber = `${countryCodes[country]?.dial_code || ''}${areaCode || '' }${phone.telephone || '' }`;
                let formattedNumber = `(${countryCodes[country]?.dial_code || ''}) ${areaCode || ''} ${phone.telephone || t('No Telephone')}`;


                return (
                    <List.Item key={index}>
                        <a href={`sip:${fullNumber}`}>
                            <PhoneFilled />
                            {formattedNumber}
                        </a>
                        {(phone.type) ? ` ${phone.type}` : ''}
                        {(phone.extension) ? ` - ext : ${phone.extension}` : '' }
                    </List.Item>
                );

            })}
        </List>
    );
}
export default TelephoneList;