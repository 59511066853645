import React from "react";
import TelephoneList from "../interview/InterviewTelephonesList";
import CountryFlags from "../CountryFlags";
import WebsiteWidget from "../WebsiteWidget";
import Notes from "../interview/InterviewNotes";
import LinkButton from "../LinkButton";
import { Row,Col,Typography,List } from "antd";
import { useTranslation } from "react-i18next";
const CollapsePreview = ({record}) => {
    const {t} = useTranslation();

    const details = [
        {'Director' : record.preview?.director || 'Unknown'},
        {'Total Hospital Beds' :  record.preview?.totalBeds || 'Unknown'} ,
        {'Total Hospital Rooms' : record.preview?.totalRooms || 'Unknown'} ,

        {'Type Of Establishment' : record.preview?.type || 'Unknown'},
        {'Level Of Care' : record.preview?.level || 'Unknown'} ,
        {'Nature Of Establishment' : record.preview?.nature || 'Unknown'} ,
    ];
    return (
        <>
            <Row gutter={[16,16]}>
                <Col>
                    <CountryFlags country={record.country} label={false} size={"small"}/>
                </Col>
            </Row>
            <Row gutter={[16,16]}>
                <Col >
                    <Typography.Title level={4}> {record.hospitalName} </Typography.Title>
                    <Typography.Text strong> {`${record.preview?.address  || ''}`} </Typography.Text>
                    <Typography.Text strong> {`${record.preview?.city  || ''}`} </Typography.Text>
                    <Typography.Text strong> {`${record.preview?.state  || ''}`} </Typography.Text>
                    <Typography.Text strong> {`${record.preview?.neighborhood  || ''}`} </Typography.Text>
                </Col>
            </Row>
            <Row style={{height: '100%'}} gutter={[16,16]}>
                <Col span={8} >
                    {<TelephoneList telephones={record.preview?.telephones || []} areaCode={record.preview?.areaCode || null} country={record.country}/>}
                    <WebsiteWidget  url={record.preview?.website}/>
                </Col>


                <Col span={8} >
                    <List
                        dataSource={details}
                        renderItem={(item) => {
                            return (
                                <List.Item>
                                    <Typography.Text strong>{t(Object.keys(item))} : </Typography.Text>  
                                    {t(Object.values(item))}
                                </List.Item>
                            )
                            
                        }}
                    />
                </Col>
                <Col span={8} >
                    <Notes notes={record.preview?.notes || []} interviewId={record.id} isPreview={true}></Notes>
                </Col>
            </Row>
            <Row gutter={[16,16]}>
                <Col span={4} offset={22}>
                    <LinkButton to={'/interviews/' + record.id}>{t('Capture')}</LinkButton>
                </Col>
            </Row>
        </>
    );
}
export default CollapsePreview;