import React from 'react';
import { useState } from 'react';
import {Typography,Image} from 'antd'

export default ({country = ' ',size,label}) => {

    const {Text} = Typography;
    const style = 'shiny'; //'flat'
    const sizes = {
        'tiny'  :16,
        'small' :24,
        'normal': 32,
        'big'   : 48,
        'huge'  :64
    }
    /*
    const flagCodes = {
        Argentina: 'ar', Bolivia: 'bo', Brazil: 'br',
        Chile: 'cl', Colombia: 'co', 'Costa Rica': 'cr',
        'Dominican Republic': 'do', Ecuador: 'ec', 'El Salvador': 'sv',
        Guatemala: 'gt', Honduras: 'hn', Mexico: 'mx',
        Nicaragua: 'ni', Panama: 'pa', Paraguay: 'py',
        Peru: 'pe', 'Puerto Rico': 'pr', Uruguay: 'uy'
    }
     */

    const flagCodes = {
        " " : "xk", "Aland Islands": "ax", "Afghanistan": "af", "Albania": "al", "Algeria": "dz", "American Samoa": "as",
        "Andorra": "ad", "Angola": "ao", "Anguilla": "ai", "Antarctica": "aq", "Antigua and Barbuda": "ag", "Argentina": "ar",
        "Armenia": "am", "Aruba": "aw", "Australia": "au", "Austria": "at", "Azerbaijan": "az", "Bahamas": "bs", "Bahrain": "bh",
        "Bangladesh": "bd", "Barbados": "bb", "Belarus": "by", "Belgium": "be", "Belize": "bz", "Benin": "bj", "Bermuda": "bm",
        "Bhutan": "bt", "Bolivia": "bo", "Bonaire Sint Eustatius and Saba": "bq", "Bosnia and Herzegovina": "ba", "Botswana": "bw",
        "Bouvet Island": "bv", "Brazil": "br", "British Indian Ocean Territory": "io", "Brunei Darussalam": "bn", "Bulgaria": "bg",
        "Burkina Faso": "bf", "Burundi": "bi", "Cambodia": "kh", "Cameroon": "cm", "Canada": "ca", "Cape Verde": "cv",
        "Cayman Islands": "ky", "Central African Republic": "cf", "Chad": "td", "Chile": "cl", "China": "cn", "Christmas Island": "cx",
        "Cocos (Keeling) Islands": "cc", "Colombia": "co", "Comoros": "km", "Congo": "cg", "Congo, The Democratic Republic Of The": "cd",
        "Cook Islands": "ck", "Costa Rica": "cr", "Croatia": "hr", "Cuba": "cu", "Curacao": "cw", "Cyprus": "cy",
        "Czech Republic": "cz", "Cote D Ivoire": "ci", "Denmark": "dk", "Djibouti": "dj", "Dominica": "dm", "Dominican Republic": "do",
        "Ecuador": "ec", "Egypt": "eg", "El Salvador": "sv", "Equatorial Guinea": "gq", "Eritrea": "er", "Estonia": "ee",
        "Ethiopia": "et", "Falkland Islands (Malvinas)": "fk", "Faroe Islands": "fo", "Fiji": "fj", "Finland": "fi", "France": "fr",
        "French Guiana": "gf", "French Polynesia": "pf", "French Southern Territories": "tf", "Gabon": "ga", "Gambia": "gm",
        "Georgia": "ge", "Germany": "de", "Ghana": "gh", "Gibraltar": "gi", "Greece": "gr", "Greenland": "gl", "Grenada": "gd",
        "Guadeloupe": "gp", "Guam": "gu", "Guatemala": "gt", "Guernsey": "gg", "Guinea": "gn", "Guinea-Bissau": "gw", "Guyana": "gy",
        "Haiti": "ht", "Heard and McDonald Islands": "hm", "Holy See (Vatican City State)": "va", "Honduras": "hn", "Hong Kong": "hk",
        "Hungary": "hu", "Iceland": "is", "India": "in", "Indonesia": "id", "Iran, Islamic Republic Of": "ir", "Iraq": "iq",
        "Ireland": "ie", "Isle of Man": "im", "Israel": "il", "Italy": "it", "Jamaica": "jm", "Japan": "jp", "Jersey": "je", "Jordan": "jo",
        "Kazakhstan": "kz", "Kenya": "ke", "Kiribati": "ki", "Korea, Democratic People's Republic Of": "kp", "Korea, Republic of": "kr",
        "Kuwait": "kw", "Kyrgyzstan": "kg", "Lao People's Democratic Republic": "la", "Latvia": "lv", "Lebanon": "lb", "Lesotho": "ls",
        "Liberia": "lr", "Libya": "ly", "Liechtenstein": "li", "Lithuania": "lt", "Luxembourg": "lu", "Macao": "mo", "Macedonia, the Former Yugoslav Republic Of": "mk",
        "Madagascar": "mg", "Malawi": "mw", "Malaysia": "my", "Maldives": "mv", "Mali": "ml", "Malta": "mt", "Marshall Islands": "mh",
        "Martinique": "mq", "Mauritania": "mr", "Mauritius": "mu", "Mayotte": "yt", "Mexico": "mx", "Micronesia, Federated States Of": "fm",
        "Moldova, Republic of": "md", "Monaco": "mc", "Mongolia": "mn", "Montenegro": "me", "Montserrat": "ms", "Morocco": "ma",
        "Mozambique": "mz", "Myanmar": "mm", "Namibia": "na", "Nauru": "nr", "Nepal": "np", "Netherlands": "nl", "Netherlands Antilles": "an",
        "New Caledonia": "nc", "New Zealand": "nz", "Nicaragua": "ni", "Niger": "ne", "Nigeria": "ng", "Niue": "nu", "Norfolk Island": "nf",
        "Northern Mariana Islands": "mp", "Norway": "no", "Oman": "om", "Pakistan": "pk", "Palau": "pw", "Palestine, State of": "ps", "Panama": "pa",
        "Papua New Guinea": "pg", "Paraguay": "py", "Peru": "pe", "Philippines": "ph", "Pitcairn": "pn", "Poland": "pl", "Portugal": "pt",
        "Puerto Rico": "pr", "Qatar": "qa", "Romania": "ro", "Russian Federation": "ru", "Rwanda": "rw", "Reunion": "re",
        "Saint Barthelemy": "bl", "Saint Helena": "sh", "Saint Kitts And Nevis": "kn", "Saint Lucia": "lc", "Saint Martin": "mf",
        "Saint Pierre And Miquelon": "pm", "Saint Vincent And The Grenadines": "vc", "Samoa": "ws", "San Marino": "sm",
        "Sao Tome and Principe": "st", "Saudi Arabia": "sa", "Senegal": "sn", "Serbia": "rs", "Seychelles": "sc", "Sierra Leone": "sl",
        "Singapore": "sg", "Sint Maarten": "sx", "Slovakia": "sk", "Slovenia": "si", "Solomon Islands": "sb", "Somalia": "so",
        "South Africa": "za", "South Georgia and the South Sandwich Islands": "gs", "South Sudan": "ss", "Spain": "es", "Sri Lanka": "lk",
        "Sudan": "sd", "Suriname": "sr", "Svalbard And Jan Mayen": "sj", "Swaziland": "sz", "Sweden": "se", "Switzerland": "ch",
        "Syrian Arab Republic": "sy", "Taiwan, Republic Of China": "tw", "Tajikistan": "tj", "Tanzania, United Republic of": "tz", "Thailand": "th",
        "Timor-Leste": "tl", "Togo": "tg", "Tokelau": "tk", "Tonga": "to", "Trinidad and Tobago": "tt", "Tunisia": "tn",
        "Turkey": "tr", "Turkmenistan": "tm", "Turks and Caicos Islands": "tc", "Tuvalu": "tv", "Uganda": "ug", "Ukraine": "ua",
        "United Arab Emirates": "ae", "United Kingdom": "gb", "United States": "us", "United States Minor Outlying Islands": "um", "Uruguay": "uy",
        "Uzbekistan": "uz", "Vanuatu": "vu", "Venezuela, Bolivarian Republic of": "ve", "Vietnam": "vn", "Virgin Islands, British": "vg",
        "Virgin Islands, U.S.": "vi", "Wallis and Futuna": "wf", "Western Sahara": "eh", "Yemen": "ye", "Zambia": "zm",
        "Zimbabwe": "zw"
    }
    const sources = { 
        'error' : `https://flagsapi.com/${flagCodes[country].toUpperCase()}/${style}/${sizes[size]}.png`,
        'default' : `https://flagcdn.com/${sizes[size]}x18/${flagCodes[country]}.png`
    }

    return (
        <>
            <Image
                src={sources.default}
                fallback={sources.error}

            />
            {(label) ? <Text strong={true} style={{display:'inline',marginLeft:'5px'}}>{country}</Text> : ''}
        </>
    );
}