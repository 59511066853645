import React, {useRef, useState,useEffect} from 'react';
import {Modal, Button} from 'antd';
import {useStores} from "../../stores/StoresProvider";

export default  ({id,onAddCallback}) => {
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const textArea = useRef<HTMLTextAreaElement|null>(null);
    const {addNote} = useStores().interviewsStore;

    const buttonLabel = 'Add';
    const modalTitle = 'Compose New Note';
    //const {Text} = Typography;

    const handleOk = async () => {
        setConfirmLoading(true);
        let note = (textArea.current) ? textArea.current.value : null;
        if(note){
            let newNote = await addNote(id, {note:note})
            onAddCallback(newNote);
        }
        setConfirmLoading(false);
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    useEffect(() => {
        if(textArea.current && visible)
            textArea.current.focus();
    }, [visible]);

    return (
        <>
            <Button type="primary" onClick={() => {
                setVisible(true)
            }}> {buttonLabel} </Button>
            <Modal
                title={modalTitle}
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <textarea rows={4} ref={textArea} style={{'width':'100%'}}/>
            </Modal>
        </>
    );
}

