import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {Table, Button, Space, Typography, Row, Col,Input, Progress} from "antd";

import {Breakpoint} from "antd/lib/_util/responsiveObserve";
import CollapsePreview from "../home/CollapsePreview";

import {useStores} from "../../stores/StoresProvider";
import InterviewsListHeader from "./InterviewsListHeader";
import {useHistory} from "react-router";
import {CheckCircleTwoTone, CustomerServiceTwoTone,SearchOutlined, QuestionCircleTwoTone} from "@ant-design/icons";
import AssignmentModal from "./AssignmentModal";
import { useTranslation } from "react-i18next";


/**
* TODO:
* fetch filters from database
* implement table-wide search as in https://codesandbox.io/s/basic-usage-ant-design-demo-2swep?file=/index.js:160-167
* Single-column search box: https://codesandbox.io/s/q-57471984-searchintable-y0n8h?fontsize=14&file=/src/App.react.js
* Fancy popup: https://codesandbox.io/s/51oz2?file=/index.js:222-236
*/

const InterviewsList = () => {

    const {t} = useTranslation();
    const {interviewsStore,currentUserStore} = useStores();
    const { role : currentUserRole }  = currentUserStore;
    const {allData, total, setFilteredData,resetFilteredData} = interviewsStore;
    const {filters, setPagination, setFilters,resetFilters, setSorter} = interviewsStore;
    const {countries,recordStatuses,agents,unassignedLabel,processStatuses,dates,priorities} = interviewsStore;

    const history = useHistory();
    const [textSearch,setTextSearch] =  useState( { searchText: '', searchedColumn: '' });
    const navigateTo = (path) => history.push(path);

    const getColumnSearchProps = dataIndex => ({
        filteredValue: filters[dataIndex] || null,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm  }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button  size="small" style={{ width: 90 }} onClick={handleClearFilters}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false,
        render: text => text
    })



    const generalColumns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            ...getColumnSearchProps('id'),
        },
        {
            title: t('Name'),
            dataIndex: 'hospitalName',
            key: 'hospitalName',
            responsive: ['md'] as Breakpoint[], // Display only on large (lg) or medium (md) screens
            ...getColumnSearchProps('hospitalName'),
        },
        {
            title: t('Country'),
            dataIndex: 'country',
            key: 'country',
            sorter: {
                compare: (a, b) => a.country.localeCompare(b.country),
                multiple: 1,
            },
            filters: countries.map((country) => { return { text: country, value : country}; }),
            filteredValue: filters.country || null,
            onFilter: (value, record) => record.country.indexOf(value) === 0,
        },
        {
            title: t('Status'),
            dataIndex: 'recordStatus',
            key: 'recordStatus',
            sorter: {
                compare: (a, b) => a.recordStatus.localeCompare(b.recordStatus),
                multiple: 2,
            },
            filters: recordStatuses.map((status) => { return { text: t(status), value : status}; }),
            filteredValue: filters.recordStatus || null,
            onFilter: (value, record) => record.recordStatus.indexOf(value) === 0,
            render: value => t(value),


        },
        {
            title: t('Last Updated'),
            dataIndex: 'lastUpdated',
            key: 'lastUpdated',
            width: 180,
            sorter: {
                compare: (a, b) => a.lastUpdated.localeCompare(b.lastUpdated),
                multiple: 5,
            },
            render: date => {
                if(!date)
                    return t('Never Updated');
                let d = new Date(date);
                return `${d.getFullYear()} ${d.toLocaleString('default', { month: 'long' })}`
            },
            filters: dates.map((date) => { return { text: date, value : date}; }),
            filteredValue: filters.lastUpdated || null,
            onFilter: (value, record) => {

                let d = new Date(record.lastUpdated);

                if(!record.lastUpdated)
                    return t('Never Updated');
                else
                    return (d.getFullYear().toString().indexOf(value) === 0)
            },
        },
        {
            title: t('Priority'),
            dataIndex: 'priority',
            key: 'priority',
            sorter: {
                compare: (a, b) => a.priority.localeCompare(b.priority),
                multiple: 5,
            },
            filters: priorities.map((priority) => { return { text: priority, value : priority}; }),
            filteredValue: filters.priority || null,
            onFilter: (value, record) => ( record.priority.indexOf(value) === 0),
            render: priority => {
                let colors =[
                    '#1890ff',
                    '#a0d911',
                    '#faad14',
                    '#fa541c',
                    '#f5222d'
                ]
                return(
                    <Progress percent={(priority / 5) * 100} status="normal" showInfo={false} strokeColor={colors[Math.round(priority)-1]}/>
                 
                )
            },
        },

    ];
    const assigmentColumn = [
        {
            title: t('Agent'),
            dataIndex: 'user',
            key: 'user',
            sorter: {
                compare: (a, b) => a.user?.name.localeCompare(b.user?.name),
                multiple: 4,
            },
            render: user => user ? user.name : t(unassignedLabel),
            filters: agents.map((agent) => { return { text: t(agent), value : agent}; }),
            filteredValue: filters.user || null,
            onFilter: (value, record) => ( record.user ? record.user.name.indexOf(value) === 0 : value === unassignedLabel ),
        },
    ];

    const processIcons = {
        "IN PROGRESS" : <CustomerServiceTwoTone twoToneColor="yellow" />,
        "REVIEW" : <CheckCircleTwoTone twoToneColor="#52c41a" />,
        "UNASSIGNED" : <QuestionCircleTwoTone twoToneColor="red" />
    }
    const processColumn = [
        {
            title: t('Progress'),
            dataIndex: 'processStatus',
            key: 'processStatus',
            width: 10,
            sorter: {
                compare: (a, b) => a.processStatus.localeCompare(b.processStatus),
                multiple: 4,
            },
            render: status => processIcons[status],
            onFilter: (value, record) => ( record.processStatus.indexOf(value) === 0),
            filteredValue: filters.processStatus || null,
            filters: processStatuses.map((status) => ({ text: processIcons[status], value : status}))
        }
    ];
    const columns  = {
        AGENT : [...generalColumns],
        SUPERVISOR : [...generalColumns,...assigmentColumn],
        QUALITY_CONTROL : [...generalColumns,...assigmentColumn,...processColumn]
    };



    const handleOnChange = (pagination, filters, sorter, extra) => {
        setPagination(pagination);
        setFilters(filters);
        setSorter(sorter);
        setFilteredData(extra.currentDataSource);
    };

    const expandable = { expandedRowRender: (record) => (
            <>
                <CollapsePreview record={record}/>
            </>
        )
    }

    const handleClearFilters = () => {
        resetFilters();
        resetFilteredData();
    }
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setTextSearch({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };



    const tableHeader = (currentData) => {
        /*
        if (!filteredData.length) {
            // Initialize filteredData in state for other components.
            //setFilteredData(currentData);
        }
         */
        return (
            <>
                <Typography.Text  type="secondary">{t(`Options`)}</Typography.Text>
                <Row gutter={[16,16]}>
                    <Col>
                        <Button onClick={handleClearFilters} >{t('Clear filters')}</Button>
                    </Col>
                    <Col>
                        {(currentUserRole !== 'AGENT') ? <AssignmentModal /> : <></> }
                    </Col>
                </Row>
                <InterviewsListHeader itemsToDisplay={total}/>
            </>
        );
    }

    return (
        <>
            <Space direction="vertical">
                <Table
                    dataSource={allData}
                    //dataSource={data}
                    columns={columns[currentUserRole]}
                    // columns={columns.map(item => ({...item, ellipsis: true}))}
                    rowKey="id"
                    pagination={{position: ["bottomRight"], pageSize: 50, hideOnSinglePage: true}}
                    // scroll={{x: true, y: 480}}
                    onChange={handleOnChange}
                    title={tableHeader}
                    expandable={expandable}
                    // rowSelection={{}}
                    // footer={() => 'Footer'}
                    //expandRowByClick={true}
                    onRow={(record, rowIndex) => {
                        return {
                            onDoubleClick: event => {
                                navigateTo(`/interviews/${record.id}`);
                            },
                        }
                    }}
                />
            </Space>
        </>
    );
};

export default observer(InterviewsList);
