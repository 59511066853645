import React, {useEffect} from 'react';
import {useStores} from "../stores/StoresProvider";
import {observer} from "mobx-react-lite";
import {Tabs,Typography} from 'antd'
import ActiveCampaign from "../components/campaign/ActiveCampaign";
import InactiveCampaign from "../components/campaign/InactiveCampaign";
import CampaignDashboard from "../components/campaign/CampaignDashboard";

export default observer(() => {
    const {Text,Title} = Typography;
    const {TabPane} = Tabs;
    const {loadCampaigns,countries} = useStores().campaignStore;
    const {load} = useStores().loaderStore;
    const pageTitle = 'Campaign management';



    useEffect(() => {
        (async () => {
            await load(loadCampaigns)
            document.title = pageTitle;
        })()
    },[])



    return (
        <>
            <CampaignDashboard />
            <Text type="secondary"> Click on a tab below to manage a country campaign! </Text>
            <Tabs>
                {countries.map((campaign, index, self) => (
                    <TabPane tab={!campaign.isActive ? `${campaign.country} (${campaign.total})` :
                        <span style={{color: "green", fontWeight: "bold"}}>{campaign.country}</span>}
                             key={campaign.country}>
                        <Title>{campaign.country}</Title>
                        {(campaign.isActive) ? <ActiveCampaign campaign={campaign}/> :
                            <InactiveCampaign campaign={campaign}/>}
                    </TabPane>
                ))}
            </Tabs>
        </>
    )
});

