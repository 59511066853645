import {action} from 'mobx';
import {api} from "../lib/api-cati";
import CurrentUserStore from "./CurrentUserStore";

export default class LoginStore  {

    currentUser:CurrentUserStore;

    constructor(currentUser:CurrentUserStore) {
        this.currentUser = currentUser;
    }

    @action login = async (values) => {
        let response:any = await api.post('login',{
            json: values
        }).json().catch(e => { return e; });

        if(response.user && response.status === 200){
            this.currentUser.setUser(response.user);
            this.currentUser.startSession();
        }else{
            this.logout();
        }
        return response;
    };

    @action.bound logout =  () => {
        this.currentUser.unsetUser();
        this.currentUser.destroySession();
        window.location.assign('/login');
    };

    isLoggedIn = () => {
        if(this.currentUser.isUserSet()) return true;

        if(!this.currentUser.isUserSet() && this.currentUser.isSessionSet()){
            this.currentUser.setUser();
            return true;
        }

        return false;
    }
}