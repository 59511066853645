/**
 * @file Collection of Antd form field generators.
 *
 * @example <caption>Example input object.</caption>
 *    {
 *        name: "internal_name",
 *        type: "Text",
 *        label: "My Friendly Field",
 *        placeholder: "Write a value here pretty please",
 *        description: "This is a very friendly input,
 *        values: {"options": ["MyVal1", "MyVal2", "MyVal3"]},
 *        validation: [{required: true, message: key + ' is required ploooox'}],
 *    }
 */
import React, {useState, useEffect,useRef } from 'react';
import {
    Form,
    Input,
    InputNumber,
    Checkbox,
    AutoComplete,
    Radio,
    Select,
    Switch,
    Tooltip,
    Button,
    Row,
    Col,
    Typography, ConfigProvider, Empty,
    Statistic
} from 'antd';

import {CopyTwoTone, DeleteTwoTone, PlusSquareTwoTone} from "@ant-design/icons";
import brands from '../../lib/brands.json'
import get from "lodash/get";
import set from "lodash/set";
import merge from "lodash/merge";
import {autorun } from "mobx";
import {useTranslation} from "react-i18next"

const {Option, OptGroup} = Select;

/**
 * Wrapper for input fields that sets description, label and copy button
 * Antd's <Form.Item name={...}> must have a single child to bind to it,
 * so we have to repeat it on every field component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const InterviewFieldWithCopyButton = (props) => {
    const {InputField, label, description, type, formValues,name,copyButtonValue} = props
    const [hasChanged,setHasChanged] = useState(false)
    const {t} = useTranslation();



    useEffect(() =>
        autorun(() => {
            //Check if equipment object
            let warehouseValue = undefined;
            if(copyButtonValue)
                warehouseValue = (name[0] === 'factEquipment') ? copyButtonValue[0].amount : copyButtonValue;
            
            setHasChanged(warehouseValue !== get(formValues,name));
    }), [])


    // useEffect(() => {
    //     console.log(name[0] + '.' + name[1] + ' rendered')
    // }, [])

    // useEffect(() => {
    //     console.log(name[0] + '.' + name[1] + ' rendered')
    // }, [])
    //
    // useEffect(() => {
    //     console.log(name[0] + '.' + name[1] + ' updated')
    // }, [formValues])

    return (
        <Tooltip title={description}>
            <Row style={{alignItems: "left"}}>
                <Form.Item style={{width: "100%", display: (type === 'Hidden') ? 'None' : ''}}>
                    <Row>
                        <Col span={9} style={{ alignSelf: 'flex-end', justifyContent: 'flex-end', display: 'flex'}}>
                            <Typography.Title level={5}>{t(label)}</Typography.Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10} style={{ alignSelf: 'flex-end', justifyContent: 'flex-end', display: 'flex' }}>
                            <CopyButton {...props} hasChanged={hasChanged}/>
                        </Col>
                        <Col span={10}  className={(hasChanged) ? 'changed-input' : ''} >
                            <InputField {...props}  />
                        </Col>
                    </Row>
                </Form.Item>
            </Row>
        </Tooltip>
    )
}

export const InterviewCompositeField = (props) => {
    const {InputField, label, description} = props;
    const {t} = useTranslation();
    return (
        <Tooltip title={description}>
            <Row style={{alignItems: "left"}}>
                <Form.Item style={{width: "100%"}}>
                    <Typography.Title level={5}>{t(label)}</Typography.Title>
                    <InputField {...props} />
                </Form.Item>
            </Row>
        </Tooltip>
    )

}


export const CustomEmptyDataMessage = ({msg, children}) => (
    msg ?
        <ConfigProvider
            renderEmpty={() => (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={msg}/>
            )}>
            {children}
        </ConfigProvider>

        : children
)


/** Component input for short text */
export const CopyButton = ({name, form, copyButtonValue, formValues, updateFormValues,hasChanged}) => {

    const {t} = useTranslation();
    // Don't show button if we don't have something to copy (but keep 0 and false)
    // if (!copyButtonValue && copyButtonValue !== 0 && copyButtonValue !== false)
    //     return null

    const hide = !copyButtonValue && copyButtonValue !== 0 && copyButtonValue !== false
    const oldValue = (Array.isArray(copyButtonValue)) ? copyButtonValue[0].amount : copyButtonValue;

    // Max 18 chars. in button, but full text in tooltip
    const maxCharsInButton = 18
    const oldValueShortened = (!hide && oldValue?.length > maxCharsInButton) ?
        oldValue.substring(0, maxCharsInButton -1) + '…' : undefined


    const copyButton = () => {
        // How to adapt form.setFieldsValue({ field: 'value' }) to grouped values?
        // const val = {}
        // val[name[0]] = {}
        // val[name[0]][name[1]] = copyButtonValue
        // form.setFieldsValue(val)
        // Easier:
        form.setFields([{name: name, value: oldValue}])
        let newValue = {}
        set(newValue, name, oldValue)
        updateFormValues(merge(formValues, newValue))
        // console.log('copyButton')
        // console.log(name)
        // console.log(formValues)
        // console.log(formValues[name[0]][name[1]])
    }

    const OptionalTooltip = ({children}) => (
        oldValueShortened ?
            <Tooltip title={oldValue}>
                {children}
            </Tooltip>

            : children
    )

    return (hide ? null :
            <OptionalTooltip>
                <Button icon={<CopyTwoTone/>} onClick={copyButton} disabled={!hasChanged}
                        style={{maxWidth: '15em'}}>
                    {oldValueShortened || t(oldValue)}
                </Button>
                <span style={{margin: '0 1em'}}>
                    {!hasChanged ? "=" : ">"}
                </span>
            </OptionalTooltip>
    )
}
/** Component input for short text */
export const TextField = ({name, validation, placeholder,formValues,form,updateFormValues}) => {

    return(
        <Form.Item noStyle name={name} rules={validation}>
            <Input placeholder={placeholder} style={{width:'70%'}} />
        </Form.Item>
    )

}
/** Component input for single-option select menus 
 * With bug on translation label, input field shows value instead of label
 * 
*/
export const AutoCompleteTextField = ({name, placeholder, validation, values}) => {
    const {t} = useTranslation();

    return (
        <Form.Item noStyle name={name} rules={validation}> 
            <AutoComplete 
                options={values.options.map((val,i) => {
                    //return {value : val,label: t(val)}
                    return {value : val}
                })}
                placeholder={placeholder}
                filterOption={(inputValue, option) =>
                    option === undefined ? false : option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
            />
        </Form.Item>
    )
}

/** Component input for integer numbers */
export const NumberField = ({name, validation, placeholder}) => (
    <Form.Item noStyle name={name} rules={validation}>
        <InputNumber min={0} placeholder={placeholder}/>
    </Form.Item>
)

export const HiddenField = ({name}) => (
    //initialValue={(values.initialized) ? copyButtonValue : '' }
    <Form.Item noStyle name={name} hidden={true}>
        <Input/>
    </Form.Item>
)

/** Component input for single-option choice buttons */
export const ChoiceField = ({name, validation, values, options,formValues}) => {
    const {t} = useTranslation();
    const getOptions = (opts = {}) => {
        return Object.keys(opts).map((key,) => {
                return {label: t(opts[key]), value: opts[key]}
        })  || undefined;
    }
    return (
        <Form.Item noStyle name={name} rules={validation}>
            <Radio.Group
                options={options || (values.dependencies) ? getOptions(values.options[get(values.dependencies,formValues)]) : getOptions(values.options) }
                optionType="button"
            />
        </Form.Item>
    )
}

/** Component input for multi-option choice buttons */
export const MultiChoiceField = ({name, validation, values, options,formValues}) => {
    const {t} = useTranslation();
    const getOptions = (opts = {}) => {
        return Object.keys(opts).map((key,) => {
                return {label: t(opts[key]), value: opts[key]}
        })  || undefined;
    }

    //options={options || (values.dependencies) ? getOptions(values.options[get(values.dependencies,formValues)]) : getOptions(values.options) }
    //optionType="button"
    return (
        <Form.Item noStyle name={name} rules={validation} >
            <Checkbox.Group
                options={ options || (values.dependencies) ? getOptions(values.options[get(values.dependencies,formValues)]) : getOptions(values.options) }
            />
        </Form.Item>
    )
}

/** Component input for single-option select menus */
export const DropdownChoiceField = ({name, placeholder, validation, values,formValues}) => {
    const {t} = useTranslation();
    const getOptions = (opts) => {
        if(opts === undefined) 
            return <Option key={name + '.opt'} value='undefined'>{'undefined'}</Option>
        return Object.keys(opts).map((key, idx) =>
            <Option key={name + '.opt' + idx} value={opts[key]}>{t(opts[key])}</Option>
        )
    }


    return (
        <Form.Item noStyle name={name} rules={validation}>
            <Select showSearch={true} placeholder={placeholder} filterOption={true}  optionFilterProp={'children'}>
                {(values.dependencies) ? getOptions(values.options[formValues[values.dependencies]]) : getOptions(values.options)}
            </Select>
        </Form.Item>
    )
}



/** Component input for single-option select menus */
export const GroupedChoiceField = ({name, placeholder, validation, values, options}) => {
    const {t} = useTranslation();
    const getOptions = (name, opts: {}) => {

        if (typeof opts === 'string') {
            return (
                <Option key={name + '_' + opts} value={opts}>
                    {t(opts)}
                </Option>
            )
        } else if (typeof opts === 'object') {
            if (Array.isArray(opts)) {
                return (
                    Object.values(opts).map(option => getOptions(name, option))
                )
            } else {
                return (
                    Object.keys(opts).map((optionGroup) => {
                            return <OptGroup label={t(optionGroup)}>
                                {getOptions(name + '_' + optionGroup, opts[optionGroup])}
                            </OptGroup>
                        }
                    )
                )
            }
        }
        return false
    }
    return (
        <Form.Item noStyle name={name} rules={validation}>
            <Select showSearch={true} placeholder={placeholder}
                    filterOption={true} options={options} allowClear>
                {getOptions(name, values.options)}
            </Select>
        </Form.Item>
    )
}

/** Component input for a boolean switch */
export const SwitchField = () => (
    // FIXME Not saving value
    // - How to distinguish false from undefined? / ===
    // - Warning: [antd: Switch] `value` is not a valid prop, do you mean `checked`?
    <Switch/>

)
export const InstalledBaseField = ({name, placeholder, form}) => {

    const [values, setValues] = useState(new Array<any>());
    const [amount, setAmount] = useState('');
    const [year, setYear] = useState('');
    const [model, setModel] = useState('');
    const [brand, setBrand] = useState('Unknown');
    const [category, col] = name;
    const {t} = useTranslation();
    //const field = useRef<Input | null>(null); // reset inputfields after add?


    const style = {
        padding:20,
        marginTop: 10,
        marginBottom: 10,
        borderBottom: '1.5px solid black',
    }

    useEffect(() => {
        let initialValues = form.getFieldValue(name);
        if (initialValues) {
            if (!Array.isArray(initialValues))
                initialValues = JSON.parse(initialValues);

            setValues(initialValues);
            if (!values)
                return;
        }

    }, [])


    useEffect(() => {
        const val = {};
        val[category] = {};
        val[category][col] = JSON.stringify(values);
        form.setFieldsValue(val);
    }, [values])


    const deleteRecord = (id) => {
        let vals = values.filter((v, index) => index !== id);// values.slice(id,id);
        setValues(vals);
    }

    const addRecord = () => {
        let _amount = Number(amount)
        if (_amount >= 0){
            let equipment = {
                model: model || 'Unknown', 
                amount: _amount, 
                year: year || 'Unknown', 
                brand: brand
            }
            setValues([...values, equipment]);
        }
    }
    const Header = (values.length) ?
        <Row style={{fontWeight: "bold"}}>
            <Col span={4}>{t('Amount')}</Col>
            <Col span={4}>{t('Year')}</Col>
            <Col span={4}>{t('Model')}</Col>
            <Col span={4}>{t('Brand')}</Col>
            <Col span={4}/>
        </Row> : <></>;

    return (
        <div style={style}>
            <Form.Item noStyle name={name} hidden={true}>
                <Input type={"text"}/>
            </Form.Item>
            <div>
                {Header}
                {values.map((equipment, index) => {
                    return (
                        <Row key={index}>
                            <Col span={4}>{equipment.amount}</Col>
                            <Col span={4}>{t(equipment.year) || t('Unknown')}</Col>
                            <Col span={4}>{t(equipment.model) || t('Unknown')}</Col>
                            <Col span={4}>{t(equipment.brand) || t('Unknown')}</Col>
                            <Col span={4} style={{alignContent: "right"}}>
                                <Button onClick={() => {
                                    deleteRecord(index)
                                }}>
                                    <DeleteTwoTone twoToneColor={"red"}/>
                                </Button>
                            </Col>
                        </Row>
                    );
                })}
            </div>
            <Row>
                <Col span={4}>
                    <Typography.Text>{t('Amount')}</Typography.Text>
                    <InputNumber
                        min={0}
                        onChange={(v:any) => (v) ? setAmount(v.toString()) : ''}
                        style={{width: "100%"}}
                    />
                </Col>
                <Col span={4}>
                    <Typography.Text>{t('Year')}</Typography.Text>
                    <InputNumber
                        style={{width: "100%"}}
                        placeholder={placeholder}
                        onChange={v => (v) ? setYear(v.toString()) : ''}
                    />
                </Col>
                <Col span={6}>
                    <Typography.Text>{t('Model')}</Typography.Text>
                    <Input
                        type={"text"}
                        onChange={e => setModel(e.target.value)}
                    />
                </Col>
                <Col span={6}>
                    <Typography.Text>{t('Brand')}</Typography.Text>
                    <Select
                        defaultValue={'Unknown'}
                        onChange={(v) => setBrand(v)}
                        showSearch={true} filterOption={true}  optionFilterProp={'children'}
                    >
                        <Select.Option value={'Unknown'}>{t('Unknown')}</Select.Option>
                        {brands.brands.map((option, index) =>
                            <Select.Option key={index} value={option}>{option}</Select.Option>
                        )}
                    </Select>
                </Col>
                <Col span={4} style={{alignSelf: 'flex-end'}}>
                    <Button onClick={addRecord}>
                        Add <PlusSquareTwoTone twoToneColor={"green"}/>
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

/**
 */
export const TelephoneField = ({name, form, values}) => {
    const [telephone, setTelephone] = useState('');
    const [extension, setExtension] = useState('');
    const [typeOfPhone, setType] = useState('');
    const [phoneRecords, setValues] = useState(new Array<any | never>());
    const [category, col] = name;
    const {t} = useTranslation();

    const style = {
        padding:20,
        marginTop: 10,
        marginBottom: 10,
    }


    useEffect(() => {
        const phones = form.getFieldValue(name)?.telephones;
        if (!phones)
            return;

        setValues(phones);
    }, [])

    useEffect(() => {
        const fields = {};
        fields[category] = {};
        fields[category][col] = (phoneRecords.length > 0) ? {telephones: phoneRecords} : '';
        form.setFieldsValue(fields);

    }, [phoneRecords])

    const deleteRecord = (id) => {
        let vals = phoneRecords.filter((v, index) => index !== id);// values.slice(id,id);
        setValues(vals);
    }

    const addRecord = () => {
        if (telephone) {
            setValues([...phoneRecords, {telephone: telephone, extension: extension, type: typeOfPhone}]);
        }
    }

    const Header = (phoneRecords.length > 0) ?
        <Row style={{fontWeight: "bold"}}>
            <Col span={8}>{t(`Telephones`)}</Col>
            <Col span={4}>{t(`Extension`)}</Col>
            <Col span={8}>{t(`Telephone Type`)}</Col>
            <Col span={4}/>
        </Row> : <></>;

    return (
        <div style={style}>
            <Form.Item noStyle name={name} hidden={true}>
                <Input type={"text"}/>
            </Form.Item>
            <Row>
                <InputNumber onChange={v => (v) ? setTelephone(v.toString()) : ''} placeholder={"Telephone Number"}
                             style={{"width": 250}}/>
                <InputNumber onChange={v => (v) ? setExtension(v.toString()) : ''} placeholder={"Extension"}/>
                <Select defaultValue={""} onChange={e => setType(e)} style={{width: 150}}>
                    <Select.Option value={""}>None</Select.Option>
                    {values.options.map((option, index) => (
                        <Select.Option key={index} value={option}>{option}</Select.Option>
                    ))}
                </Select>
                <Button onClick={addRecord}>
                    Add
                </Button>
            </Row>
            {Header}
            <div>
                {phoneRecords.map((phone, index) => {
                    return (
                        <Row key={index}>
                            <Col span={8}>{phone.telephone}</Col>
                            <Col span={4}>{(phone.extension) ? phone.extension : ''}</Col>
                            <Col span={8}>{(phone.type) ? phone.type : ''}</Col>
                            <Col span={4} style={{alignContent: "right"}}>
                                <Button onClick={() => {
                                    deleteRecord(index)
                                }}>
                                    <DeleteTwoTone twoToneColor={"red"}/>
                                </Button>
                            </Col>
                        </Row>
                    );
                })}
            </div>
        </div>
    )
}
export const SumField = ({name, form, copyButtonValue, values}) => {
    let warehouseValue = (Array.isArray(copyButtonValue)) ? copyButtonValue[0].amount : copyButtonValue;
    const [sum,setSum] = useState(0);

    useEffect(() => {
        for (let column in values.sum){
            let element = form.getFieldInstance([name[0],values.sum[column]]);
            if(element.onChange){
                let f = element.onChange;
                element.onChange = (e) => {
                    f(e);
                    sumBeds();
                    return;
                }
            }else{
                element?.addEventListener("input",() => {
                    sumBeds();
                },true)
            }
        }


    },[])

    const sumBeds = () => {
        let total = 0;
        let interviewValues = form.getFieldValue(`factEquipment`);
        Object.values(values.sum).forEach((col:any) => {
            total += (parseInt(interviewValues[col]) || 0);
        })
        setSum(total)
    }


    if(!form)
        return

    return (<Statistic value={sum || warehouseValue}/>);
}
