import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom'
import {observer} from "mobx-react-lite";
import {Form, message,  Typography, Tabs, Button, Space, Result} from 'antd';

import merge from "lodash/merge";

import {useStores} from "../stores/StoresProvider";
import InterviewDashboard from "../components/interview/InterviewDashboard";
import SaveModal from '../components/interview/InterviewSaveModal'
import {
    AutoCompleteTextField,
    ChoiceField,
    MultiChoiceField,
    DropdownChoiceField,
    GroupedChoiceField,
    HiddenField,
    InstalledBaseField,
    InterviewCompositeField,
    InterviewFieldWithCopyButton,
    NumberField,
    SwitchField,
    TelephoneField,
    SumField,
    TextField
} from "../components/interview/FormFields";
import {useTranslation} from "react-i18next";


const InterviewForm = ({id}) => {
    const {Title} = Typography
    const {TabPane} = Tabs

    const [form] = Form.useForm()

    const {updateInterview, interviews, warehouseInterviews} = useStores().interviewsStore;
    const {questions, dependencies, categories} = useStores().questionsStore;

    const {formValues, updateFormValues, formSelectOptions, setFieldSelectOptions} = useStores().formStore;
    const [activeTab, setActiveTab] = useState('')

    const quickSave = (e) => {
        if ((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)  && e.keyCode == 83) {
            e.preventDefault();
            form.submit();
        }
    }
    const {t} = useTranslation();

    useEffect(() => {
        document.addEventListener("keydown", quickSave, false);
        return () => {
            document.removeEventListener("keydown",quickSave,false);
        }
    }, [])

    useEffect(() => {
        // TODO: get rid of categories[0] in if
        if (!activeTab && categories[0])
            setActiveTab('FormTab_' + categories[0])
    }, [categories])


    if (!interviews[id])
        return <Result
            status="warning"
            title="Record not found, is it in active campaigns?"/>

    if (!warehouseInterviews[id])
        return <Result
            status="error"
            title="Record not found in Warehouse!"/>

    // Catalog of fields to get component from string type
    const formFields = {
        Text: TextField,
        AutoCompleteText: AutoCompleteTextField,
        Number: NumberField,
        Choice: ChoiceField,
        MultiChoice: MultiChoiceField,
        DropdownChoice: DropdownChoiceField,
        GroupedChoice: GroupedChoiceField,
        Switch: SwitchField,
        TEXT: TextField,
        NUMBER: NumberField,
        COMBOBOX: AutoCompleteTextField,
        OPTIONS: ChoiceField,
        BOOL: SwitchField,
        Telephone: TelephoneField,
        InstalledBase: InstalledBaseField,
        Sum: SumField,
        Hidden: HiddenField
    }

    const changeFormTab = (direction = 1) => {
        const activeIdx = categories.findIndex(val => 'FormTab_' + val == activeTab)
        if (categories[activeIdx + direction]) {
            setActiveTab('FormTab_' + categories[activeIdx + direction])
            window.scrollTo(0, 0)
        }
    }

    const onFormTabChange = tabKey => setActiveTab(tabKey)

    const SaveFormModal = (<Space direction="vertical">
        <Button onClick={() => changeFormTab(-1)}>{t('Previous Page')}</Button>
        <Button onClick={() => changeFormTab()}>{t('Next Page')}</Button>
        <SaveModal
            defaultSelectValue={(interviews[id]) ? interviews[id].recordStatus : ''}
            form={form}
        />
    </Space>)


    const FormTabs = () => {
        return (
            <Tabs tabPosition="left" tabBarExtraContent={SaveFormModal}
                  activeKey={activeTab} onChange={onFormTabChange}>
                {
                    categories.map((category, catIdx) => {

                        const catiQuestions = Object.values(questions).filter(q => q.category == category)

                        const catiQuestionsSorted = catiQuestions.sort((a, b) => a.order - b.order)

                        return (
                            <TabPane
                                key={'FormTab_' + category}
                                tab={t(category)}
                            >
                                <Title>{t(category)}</Title>
                                {
                                    catiQuestionsSorted.map((question, questIdx) => {
                                        const {db_col_name, category_group} = question;
                                        const InputField = formFields[question.type];

                                        if (!InputField) {
                                            console.log("Field not found for " + question.type)
                                            return false
                                        }

                                        //setFieldSelectOptions(db_col_name,
                                            //customQuestionOptions(question.db_col_name, interviews[id]))

                                        //if (question.values && question.values.composite_field)
                                        if (question.values?.composite_field)
                                            return <InterviewCompositeField
                                                form={form}
                                                InputField={InputField}
                                                copyButtonValue={warehouseInterviews[id][category_group][db_col_name]}
                                                name={[category_group, db_col_name]}
                                                key={`Field${db_col_name}`}
                                                {...question}
                                            />
                                        else
                                            return <InterviewFieldWithCopyButton
                                                form={form}
                                                InputField={InputField}
                                                name={[category_group, db_col_name]}
                                                key={`Field${db_col_name}`}
                                                options={formSelectOptions[db_col_name]}
                                                {...question}
                                                copyButtonValue={warehouseInterviews[id][category_group][db_col_name]}
                                                formValues={formValues}
                                                updateFormValues={updateFormValues}
                                            />
                                    })
                                }
                            </TabPane>
                        );
                    })
                }
            </Tabs>
        )
    }


    const onFinish = async values => {
        /**
         * values parameter is incomplete
         * Only gathers rendered fields
         * Getting all values from form and removing the notes
         * property seems to work out
         */
        values = form.getFieldsValue(true);
        delete values.notes;
        console.log('Received values of form: ', values);

        if(values.value)
            console.log(values?.value)


        values.hospitalName = values.hospitalName || interviews[id].hospitalName;
        values.recordStatus = values.recordStatus || interviews[id].recordStatus;
        values.country = interviews[id].country;
        values.lastUpdated = interviews[id].lastUpdated;

        const response = await updateInterview(id, values);
        if (response.data === 'ok')
            message.success('Saved');
    }

    const initVals = () => {
        //Initializing entire interview
        Object.keys(interviews[id]).forEach(key => {
            if((key === 'notes'|| key === 'preview') || interviews[id][key] === null)
                return ;
            if(typeof(interviews[id][key]) === 'object' ) {
                   
                if (Object.keys(interviews[id][key]).length <= 0)
                    interviews[id][key] = {} ;
                Object.keys(warehouseInterviews[id][key]).forEach(field => {
                    if(interviews[id][key][field] === undefined){
                        if (field.match(/^c01.*/) || questions[field]?.values?.simple_equipment_field )
                            interviews[id][key][field] = warehouseInterviews[id][key][field][0]?.amount || null;
                        else
                            interviews[id][key][field] = warehouseInterviews[id][key][field];
                    } else {
                        interviews[id][key][field] = interviews[id][key][field];
                   }
               })
           }
        })
        updateFormValues((interviews[id]))
        return interviews[id]
    }


    const handleFormValuesChange = (changedValues, previousValues) => {
        updateFormValues(merge({},changedValues))
    }



    return (
        <Form
            name={"interview_" + id + "_form"}
            form={form}
            initialValues={initVals()}
            onFinish={onFinish}
            onValuesChange={handleFormValuesChange}
            //onFinishFailed={onFinish}
            scrollToFirstError
            labelCol={{span: 8}}
            wrapperCol={{span: 24}}
        >
            <FormTabs/>
        </Form>
    )
}


export default observer(() => {
    //const {Text, Title} = Typography;
    // Messy typing... https://github.com/reach/router/issues/141
    // (props: RouteComponentProps) => {
    // const id = Number((props.match.params as any).id);

    // Wait for react-router hooks TS support: https://github.com/ReactTraining/react-router/issues/6885
    // @ts-ignore (Property 'id' does not exist on type '{}'.  TS2339)

    const {id} = useParams()
    const {fetchQuestions} = useStores().questionsStore
    const {fetchInterview, fetchWarehouseInterview,interviews} = useStores().interviewsStore
    const {load} = useStores().loaderStore

    useEffect(() => {
        (async () => {
            await load(async () => {
                await fetchQuestions();
                await fetchInterview(id);
                await fetchWarehouseInterview(id);
                document.title = `${interviews[id]?.hospitalName} - ${interviews[id].country}`;
            })
        })()
    }, [])


    return (
        <>
            <InterviewDashboard id={id}/>
            <InterviewForm id={id}/>
        </>
    )

})
