import React from "react";

import InterviewsStore    from "./InterviewsStore";
import LoginStore         from "./LoginStore";
import LoaderStore        from "./LoaderStore";
import CampaignStore      from "./CampaignStore";
import CurrentUserStore   from "./CurrentUserStore";
import UsersStore         from "./UsersStore";
import QuestionsStore     from "./QuestionsStore";
import FormStore          from "./FormStore";

type StoresContextValue = {
    loginStore           : LoginStore,
    interviewsStore      : InterviewsStore,
    loaderStore          : LoaderStore,
    campaignStore        : CampaignStore
    currentUserStore     : CurrentUserStore,
    usersStore           : UsersStore,
    questionsStore       : QuestionsStore,
    formStore            : FormStore,
}

const StoresContext = React.createContext({ } as StoresContextValue);

export const StoresProvider =  ({children}) => {
    const currentUserStore       = new CurrentUserStore();
    const loginStore             = new LoginStore(currentUserStore);
    const interviewsStore        = new InterviewsStore();
    const loaderStore            = new LoaderStore();
    const campaignStore          = new CampaignStore();
    const usersStore             = new UsersStore();
    const questionsStore         = new QuestionsStore();
    const formStore              = new FormStore();

    return (
        <StoresContext.Provider value={{ loginStore,interviewsStore,loaderStore,campaignStore,
        currentUserStore,usersStore, questionsStore, formStore}}>
            {children}
        </StoresContext.Provider>
    );
}
export const useStores = () => React.useContext(StoresContext);