import ky from "ky";
import {message} from "antd";
export const api = ky.create({
    prefixUrl: '/api/v1',
    // Allow cookies
    credentials: "include",
    timeout: 50000, // ms
    hooks: {
         afterResponse: [
             async (request,options,response) => {
                 try {
                     if (response.status === 401) {
                         window.location.assign('/login?next=' + encodeURIComponent(window.location.pathname));
                     }
                     if(!response.ok) {
                         throw new Error(response.statusText);
                     }
                 }catch (e)  {
                     await message.error(e.message);
                     window.location.assign('/');
                 }
                 return response;
             }
         ]

    }

});

// Inject UMD ky version in DevTools:
// var script = document.createElement('script');script.src = "https://cdn.jsdelivr.net/npm/ky@0.24.0/umd.js";document.getElementsByTagName('head')[0].appendChild(script); let ky = globalThis.ky;
