import React, {useEffect, useState} from 'react'
import {Button, Switch, Typography, Select, Modal, Form } from "antd";
import {useStores} from "../../stores/StoresProvider";
import { useTranslation } from 'react-i18next';

export default ({defaultSelectValue = '',form}) => {

    const {fetchDistinctRecordStatuses,recordStatusOptions} = useStores().interviewsStore;
    const { role: currentUserRole }  = useStores().currentUserStore;
    const {Title} = Typography;
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [migrationReady, setMigrationReady] = useState(form.getFieldsValue(true).processStatus == 'REVIEW');
    const {t} = useTranslation();
    const buttonLabel = t('Save Changes')
    const modalTitle = buttonLabel;


    const handleOk = async () => {
        setConfirmLoading(true);
        form.submit();
        setConfirmLoading(false);
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleMigrate = () => {
        console.log('Migrating')
        setVisible(false);
    };

    let buttons = [
        <Button key={1} type={'primary'} onClick={handleOk}>{t('Save')}</Button>,
        <Button key={2} onClick={handleCancel}>{t('Cancel')}</Button>,
        <Button key={3} onClick={handleMigrate} danger={true} hidden={!((currentUserRole !== 'AGENT' && currentUserRole !== 'SUPERVISOR') && migrationReady)}>{t('Migrate')}</Button>
    ]

    useEffect(() => {
        (async () => {
            await fetchDistinctRecordStatuses();
        })();
    },[]);



    return(
    <>
        <Button type="primary" onClick={() => {setVisible(true)}}> {buttonLabel} </Button>
        <Modal
            title={modalTitle}
                visible={visible}
                footer={ buttons }
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >


                <Title level={5}>{t('Status')}</Title>
                <Form.Item noStyle name={'recordStatus'} >
                    <Select
                        style={{minWidth:300}}
                        //onChange={(value) => {  }}
                        defaultValue={defaultSelectValue}

                    >
                        {recordStatusOptions.map(option => {
                            return(
                                <Select.Option
                                    value={option}
                                    key={option}
                                >
                                    {t(option)}
                                </Select.Option> );

                        })}
                    </Select>
                </Form.Item>

            <Switch
                defaultChecked={ migrationReady }
                onChange={
                    (state) => {
                        form.getFieldsValue(true).processStatus = (state) ? 'REVIEW' : 'IN PROGRESS';
                        setMigrationReady(state);
                    }
                }
            />
        </Modal>
    </>
    );

};