import React from 'react';
import {useStores} from "../../stores/StoresProvider";
import {observer} from "mobx-react-lite";
import {Progress, Row,Col,Typography,Statistic} from 'antd'

export default observer(() => {
    const {Text} = Typography;
    const {campaignStore} = useStores()
    const {totalRecords,totalCountries,statusesSum,totalActiveRecords,totalActiveCampaigns} = campaignStore;
    const strokeColors = {
        "Establishment refused to participate" : '#d90c0c', //{from: '#108ee9',to :'#d90c0c'},
        "Correct contact info but no response" : '#f58401',
        "Updated" :  '#87d068',
        "Establishment does not qualify" : '#d90c0c',
        "Telephone does not work" :  '#f58401',
        "New" :  '',
    }

    return (
        <>
            <Row style={{margin:"5% 0"}}>
                <Col span="12">
                    {statusesSum.map(statuses => (
                        <div key={statuses.status}>
                            <Text>{statuses.status}</Text>
                            <Progress percent={Math.floor(statuses.amount * 100 / totalRecords )}
                                      strokeWidth={10}
                                      width={100}
                                      strokeColor={ strokeColors[statuses.status] }
                            />
                        </div>
                    ))}
                </Col>
                <Col span={12}>
                    <Row style={{paddingLeft : 15}}>
                        <Col span="8"> <Statistic value={totalRecords} title={'Total records'} /> </Col>
                        <Col span="8"> <Statistic value={totalCountries} title={'Total countries'} /> </Col>
                        <Col span="8"> <Statistic value={totalActiveRecords} title={'Total remaining records'} /> </Col>
                    </Row>
                    <Row style={{paddingLeft : 15}}>
                        <Col span="8"> <Statistic value={totalActiveCampaigns} title={'Total active campaigns'} /> </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
});

