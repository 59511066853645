import {observable, action, toJS} from 'mobx'
import {api} from "../lib/api-cati";

export default class UsersStore {

    @observable users = new Array<any>();
    @observable agents = new Array<any>();

    @action.bound fetchAgents = async () => {
        let {data} = await api.get('users',{searchParams:{role:'AGENT'}}).json();
        this.agents = data;
    }
    @action.bound setUsers = (data) => {
        this.users = data;
    }
    @action.bound fetchUsers = async () => {
        let {data} = await api.get('users').json();
        this.users = data;
    }
    @action addUser = async (user) => {
        const response: APIResponse = await api.post(`users/`, {
            json: user,
        }).json()

        if (response.ok) {
            // FIXME: user list not updating with new user
            this.users.push(response.newUser)
            console.log(toJS(this.users))
            // this.setUsers(this.users)
        }
        return response
    }

    @action deleteUser = async (user) => {
        await api.delete(`users/${user.id}`, {}).json()
    }

    @action editUser = async (user) => {
        let response:APIResponse = await api.put(`users/${user.id}`, {
            json: user,
        }).json()
        return response;

    }
    @action editUserPassword = async (user) => {
        let response:APIResponse = await api.put(`users/${user.id}/password`, {
            json: user,
        }).json()
        return response;
    }


}
