import {action} from 'mobx'

const LOCAL_STORAGE_KEY = 'user';
export default class CurrentUserStore {

    id:number|undefined = undefined;
    name:string = '';
    lastname:string = '';
    role:string = '';


    @action.bound isUserSet() {
        return (this.id !== undefined);
    }

    @action.bound isSessionSet() {
        let data = this.getSessionData();
        return (Object.keys(data).length > 0);
    }

    getSessionData() {
       return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
    }


    @action.bound setUser(userEntity?){
        if(!userEntity)
            userEntity = this.getSessionData();

        this.id         = userEntity.id;
        this.name       = userEntity.name;
        this.lastname   = userEntity.lastname;
        this.role       = userEntity.role;
    }

    unsetUser() {
        this.id         = undefined;
        this.name       = '';
        this.lastname   = '';
        this.role       = '';

    }

    startSession() {
        localStorage.setItem(LOCAL_STORAGE_KEY,JSON.stringify({
            id       : this.id,
            name     : this.name,
            lastname : this.lastname,
            role     : this.role
        }))
    }

    destroySession() {
        localStorage.clear();
        document.cookie = '';
    }

    getUserRecords() { return; }
}
