import React, {useState,useEffect} from 'react';
import {Comment, Typography, Popconfirm, message, Empty} from "antd";
import NoteModal from "./NoteModal";
import {useStores} from "../../stores/StoresProvider";
import {LeftSquareTwoTone, QuestionCircleOutlined, RightSquareTwoTone} from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

const Notes = ({notes = [],interviewId,isPreview = false}) => {
    const {t} = useTranslation();
    const {id : currentUserId,  name : currentUserName, lastname : currentUserLastname  } = useStores().currentUserStore;
    const {deleteNote} = useStores().interviewsStore;
    const [_notes,setNotes] = useState(new Array<any>());
    const [currentNote,setCurrentNote] = useState(0);

    useEffect(() => {
        let n = notes.slice()
            .sort((a:any, b:any) => {
                return new Date(b.date).getTime() - new Date(a.date).getTime();
            })
        setNotes(n);
    },[notes])


    const _deleteNote = async (id) => {
        if(await deleteNote(id,interviewId)) {
            let filtered = _notes.filter((note:any) => {
                return note.id !== id
            })
            setNotes(filtered);
            message.success(t('Note deleted'))
            setCurrentNote(0);
        }
    }

    const _addNote = (note) => {
        let author = { name : currentUserName , lastname : currentUserLastname, id : note.author}
        note.author = author;
        setNotes([note,..._notes]);
        setCurrentNote(0);
    }

    const addbutton = () => {
        return (isPreview) ? <></> : <NoteModal id={interviewId} onAddCallback={_addNote}/>
    }

    const deleteButton = <Popconfirm
        title={t('Are you sure you want to continue？')}
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        onConfirm={async (e) => { await _deleteNote(_notes[currentNote].id)}}
    >
        <a href="#">{t('Delete Note')}</a>
    </Popconfirm>;

    if (_notes.length <= 0) return <>{addbutton()} <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></>;

    return(
    <>
        {addbutton()}
        
        {/*
        TODO: Check user and if its author allow to edit note
        <Button type={"primary"} color={"yellow"}>Edit</Button> <- should be note modal
        (note.author?.id === currentUserId)
        */
        }
        <div key={_notes[currentNote].id}>
            <Comment
                author={`${_notes[currentNote].author?.name || t('Unknown')} ${_notes[currentNote].author?.lastname || ''}`}
                content={_notes[currentNote].note}
                datetime={_notes[currentNote].date.slice(0,10)}
                style={{minHeight:150}}
            />
            {(currentNote < _notes.length - 1) ? <RightSquareTwoTone style={{float:'right'}}onClick={(e) => { setCurrentNote(currentNote + 1)}} /> : <></>}
            {(currentNote > 0) ? <LeftSquareTwoTone  onClick={(e) => { setCurrentNote(currentNote - 1)}}/> : <></>}

            {(_notes[currentNote].author?.id === currentUserId) ? deleteButton : <></>}
        </div>
    </>);
}
export default Notes;
//export default observer(Notes);