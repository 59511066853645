import {observable, action, computed} from 'mobx'
import {api} from "../lib/api-cati";
import set from 'lodash/set'

export default class QuestionsStore {

    @observable questions = <Question>{}

    // Questions with dependencies, to keep on state
    dependencies = {}

    @action.bound fetchQuestions = async () => {
        const {data} = await api.get('questions').json()

        // Transform array to object, indexed by field name
        let questions:Question = <Question>{}
        data.forEach(q => {
            questions[q.db_col_name] = q
        })

        this.questions = questions
    }

    @computed get categories() {
        // if (!this.questions)
        //     return undefined
        let categories: string[] = [];
        Object.values(this.questions).forEach((question) => {
            if (!categories.includes(question.category)) {
                categories.push(question.category);
            }
        })

        return categories;
    }
}