import {observable,action} from "mobx";


export default class LoaderStore {

    @observable isLoading = true;

    @action.bound load = async (AsyncFetchCallback) =>{
        this.isLoading = true;
        await AsyncFetchCallback();
        this.isLoading = false;
    }

}
