import React from 'react';
import {HomeFilled} from "@ant-design/icons";

const WebsiteWidget = ({url}) => {

    const render = () => {
        if(!url)
            return <></>
        return (url.match(/^.*\..*$/)) ?
                <a target={'_blank'} href={`http://${url}` } >
                    <HomeFilled/> Website
                </a>
            : <></>;

    }

    return render();

};

//export default observer(InterviewDashboard);
export default WebsiteWidget;
