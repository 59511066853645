import {Button, Checkbox, message,Space} from "antd";
import React, {useState} from "react";
import {useStores} from "../../stores/StoresProvider";


export default (props) => {

    const {campaign} = props;
    const {campaignStore} = useStores()
    const {toggleCampaign} = campaignStore;
    const [isPosting,setIsPosting] = useState(false);
    const [statuses,setStatuses] = useState(campaign.statuses.map(item => { return item.status || 'New'; })); //FIXME: USE SERVICE WORKER OPTIONS

    return(
        <>
            <div style={{padding:20}}>
                <p>Select campaign record status target</p>
                <h4>Status</h4>
                <Space>
                <Checkbox.Group
                    defaultValue={statuses}
                    onChange={group => setStatuses(group) }
                    options={campaign.statuses.map(item => (
                    {
                        label : `${item.status || 'New'} (${item.amount})`,
                        value : item.status || 'New',
                    }
                ))}
                />
                </Space>
            </div>

            <div style={{ float : "right", margin : 10}}>
                <Button
                    type="primary"
                    onClick={ async (event) => {

                        setIsPosting(true)
                        let r:any = await toggleCampaign(campaign,statuses)
                        campaign.activeRecords = r.data;
                        setIsPosting(false)

                        campaign.isActive = true;
                        campaign.nAssignedUsers = 0;

                        message.success("Campaign enabled successfully!")
                    }}
                    loading={isPosting}
                >
                    Enable
                </Button>
            </div>
        </>
    );
}
