
import React,{useState} from "react";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";

//import {en} from  "../lib/translation.en.json"
import es from  "./translation.es.json"


i18n
    .use(initReactI18next)
    .init({
        resources: es,    
        lng: "es", // if you're using a language detector, do not define the lng option
        fallbackLng: "en",
        interpolation: { escapeValue: false } 
    });


export default i18n;