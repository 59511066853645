import {api} from "../lib/api-cati";
import {action, computed, observable} from "mobx";


export default class CampaignStore {

    @observable countries: Array<any>;

    constructor() {
        this.countries = new Array<any>();
    }


    @action.bound loadCampaigns = async () => {
        let {data} = await api.get('campaigns').json();
        this.countries = data;
    }

    @action toggleCampaign = async (campaign, filters?: any) => {
        let {isActive, country} = campaign;
        let affected = (!isActive) ? await api.post(`campaigns/${country}`, {
            json: {statuses: filters},
            timeout: false
        }).json() : api.delete(`campaigns/${country}`);

        return (affected !== undefined && !isActive) ? affected : 'ok'
    }

    @computed get totalRecords() {
        let total = 0;
        this.countries.forEach((campaign) => {
            total += campaign.total;
        })
        return total;
    }

    @computed get totalActiveCampaigns() {
        let total = 0;
        this.countries.forEach((campaign) => {
            if (campaign.isActive)
                total++;
        })
        return total;
    }

    @computed get totalActiveRecords() {
        let count = 0;
        this.countries.forEach((campaign) => {
            if (campaign.isActive)
                count += parseInt(campaign.activeRecords);
        })
        return count;
    }


    @computed get totalCountries() {
        return this.countries.length || 0;
    }

    @computed get statusesSum(): Array<any> {
        let list = new Array<any>();
        this.countries.forEach(campaign => {
            campaign.statuses.forEach(status => {
                if (list[status.status] === undefined)
                    list[status.status] = 0;

                list[status.status] += parseInt(status.amount)
            })
        })
        return Object.keys(list).map((key) => {
            return {status: (key === 'null') ? 'New' : key, amount: list[key]}
        });
    }


}
