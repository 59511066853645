import {Button, Form, Input, message, Modal, Row, Select} from "antd";
import {useStores} from "../../stores/StoresProvider";
import React, {useRef, useState} from "react";
import {FormInstance} from "antd/es/form";
import {PlusCircleOutlined} from "@ant-design/icons";

export default () => {


    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const {addUser,fetchUsers} = useStores().usersStore;

    const buttonLabel = <><PlusCircleOutlined />Add user</>;
    const modalTitle = 'Add new user';
    const ref = useRef<FormInstance|null>(null);



    const layout = {
        labelCol: {span: 12},
        wrapperCol: {span: 24},
    };

    const handleOk = async () => {
        if(!ref.current)
            return;
        setConfirmLoading(true);
        await ref.current.submit();
        setConfirmLoading(false);
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const onFinish = async (values) => {
        const response = await addUser(values);
        if (response.ok) {
            await fetchUsers();
            message.success({
                content: `User ${values.username} created successfully`
            })
        } else {
            message.error({
                content: 'Error: '+ response.error
            })
        }
    };


    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Button onClick={() => {
                setVisible(true)
            }}> {buttonLabel} </Button>
            <Modal
                title={modalTitle}
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <Row justify="center">
                    <Form
                        {...layout}
                        ref={ref}
                        name="adduser"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={
                                [
                                    {required: true, message: 'Please input your name!'},
                                    {min: 2, message: 'Your name should be at least 4 characters long!'}
                                ]
                            }
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Lastname"
                            name="lastname"
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="E-mail"
                            name="email"
                            rules={
                                [
                                    {required: true, message: 'Please input your email!'},
                                    {type: "email", message: 'Please input a valid email!'},
                                ]
                            }
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Username"
                            name="username"
                            rules={
                                [
                                    {required: true, message: 'Please input your username!'},
                                    {min: 4, message: 'Your username should be longer than 4 characters!'},
                                ]
                            }
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={
                                [
                                    {required: true, message: 'Please input your password!'},
                                    {min: 8, message: 'Your password should be at least 8 characters long!'},
                                ]
                            }
                        >
                            <Input.Password/>
                        </Form.Item>

                        <Form.Item
                            label="Repeat password"
                            name="verification"
                            dependencies={['password']}
                            rules={[
                                {required: true, message: 'Please verify your password!'},
                                ({getFieldValue}) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('The two passwords that you entered do not match!');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>

                        <Form.Item
                            label="Role"
                            name="role"
                            initialValue={"AGENT"}
                        >
                            <Select>
                                <Select.Option value={"AGENT"}>Agent</Select.Option>
                                <Select.Option value={"SUPERVISOR"}>Supervisor</Select.Option>
                                <Select.Option value={"QUALITY_CONTROL"}>Quality Control</Select.Option>
                            </Select>
                        </Form.Item>

                    </Form>
                </Row>
            </Modal>
        </>
    );
}