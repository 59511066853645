import React,{useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useStores} from "../stores/StoresProvider";
import {Form, Input, Button, Row, Col, Checkbox, message} from 'antd';
import {Redirect} from "react-router-dom";

export default observer(
    () => {
        const {loginStore} = useStores();
        const [isLoggedIn,setLoggedIn] = useState(false) ;


        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };
        const tailLayout = {
            wrapperCol: {offset: 12, span: 16},
        };
        const onFinish = values => {
            loginStore.login(values).then(response => {
                if(response.status !== 200){
                    setLoggedIn(false);
                }
                setLoggedIn(true);

            });
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        if(isLoggedIn) // TODO: Add to={&next}
            return <Redirect to="/"  exact={true}/>

        return (
            <>
                <div className="container">
                    <Row justify="center">
                        <Col span="16">
                            <h2>Global Heath Intelligence</h2>
                            <b><i>Computer Assisted Telephone Interviewing system</i></b>
                            <p>Please Login with your CATI Account</p></Col>
                    </Row>

                    <Row justify="center">
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{remember: true}}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="Username"
                                name="username"
                                rules={[{required: true, message: 'Please input your username!'}]}
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{required: true, message: 'Please input your password!'}]}
                            >
                                <Input.Password/>
                            </Form.Item>

                            <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Row>

                </div>
            </>
        );
    },
);
