import React, {useEffect} from 'react';
import {useStores} from "../stores/StoresProvider";
import {Typography} from 'antd';

import UserList from "../components/user/UserList";
import AddModal from "../components/user/AddModal";


export default () => {
    const {Title} = Typography;
    const {load} = useStores().loaderStore;
    const {fetchUsers} = useStores().usersStore;
    const pageTitle = 'User management';

    useEffect(() => {
        (async () => {
            await load(async () => {
                await fetchUsers();
            });
        })()
        document.title = pageTitle;
    },[load])

    return (
        <>
            <Title level={4}>Users</Title>
            <AddModal />
            <UserList />
        </>
    );
};

