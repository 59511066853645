import {Button, Form, Input, Modal, Row, Select,message} from "antd";
import React, {useRef, useState} from "react";
import {EditOutlined} from "@ant-design/icons";
import {FormInstance} from "antd/es/form";
import {useStores} from "../../stores/StoresProvider";

export default ({user}) => {

    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const {editUser,fetchUsers} = useStores().usersStore;
    const buttonLabel = '';
    const modalTitle = 'Edit user';
    const ref = useRef<FormInstance|null>(null);

    const layout = {
        labelCol: {span: 12},
        wrapperCol: {span: 8},
    };


    const handleOk = async () => {
        if(!ref.current)
            return;
        setConfirmLoading(true);
        await ref.current.submit();
        setConfirmLoading(false);
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const onFinish = async(values) => {
        values.id = user.id;
        const response = await editUser(values);
        if (response.ok) {
            await fetchUsers();
            message.success({
                content: `User ${user.username} created successfully`
            })
        } else {
            message.error({
                content: `Error ${response.error} `
            })
        }
    };
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>

            <Button type="primary" icon={<EditOutlined/>} onClick={() => {
                setVisible(true)
            }}>{buttonLabel}</Button>
            <Modal
                title={modalTitle}
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <Row justify="center">
                    <Form
                        {...layout}
                        ref={ref}
                        name="editUser"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            label="Name"
                            name="name"
                            initialValue={user.name}
                            rules={
                                [
                                    {required: true, message: 'Please input your name!'},
                                    {min: 2, message: 'Your name should be at least 4 characters long!'}
                                ]
                            }
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Lastname"
                            name="lastname"
                            initialValue={user.lastname}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="E-mail"
                            name="email"
                            initialValue={user.email}
                            rules={
                                [
                                    {required: true, message: 'Please input your email!'},
                                    {type: "email", message: 'Please input a valid email!'},
                                ]
                            }
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Role"
                            name="role"
                            initialValue={user.role}
                        >
                            <Select>
                                <Select.Option value={"AGENT"}>Agent</Select.Option>
                                <Select.Option value={"SUPERVISOR"}>Supervisor</Select.Option>
                                <Select.Option value={"QUALITY_CONTROL"}>Quality Control</Select.Option>
                            </Select>
                        </Form.Item>

                    </Form>
                </Row>
            </Modal>
        </>
    );
}