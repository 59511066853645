import React from "react";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import {render} from "react-dom";
import {StoresProvider} from "./stores/StoresProvider";
import "./lib/i18n";

render(
    <>
        <BrowserRouter>
            <StoresProvider>
                <App />
            </StoresProvider>
        </BrowserRouter>
    </>,
    document.getElementById("root")
);
