import React, {useEffect, useState} from 'react';
import {Modal, Button, Radio, List, Typography, Row, Col, Slider, InputNumber, Statistic, message} from 'antd';
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores/StoresProvider";
import { useTranslation } from 'react-i18next';

export default  observer(() => {
    const {t} = useTranslation();
    const {agents,fetchAgents} = useStores().usersStore;
    const {filteredData,total,unassigned,allData} = useStores().interviewsStore;
    const {assignInterviewsToAgent,setInterviews,setFilteredData} = useStores().interviewsStore;

    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [selectedAgent,setSelectedAgent] = useState(0);
    //const [selectedRecords,setSelectedRecords] = useState(new Array<any>());
    const [amountOfRecords,setAmountOfRecords] = useState(1);
    const buttonLabel = t('Assign to Agents');
    const modalTitle = t('Assign agents');

    const {Text} = Typography;

    useEffect(() => {
        (async () => {
            await fetchAgents();
        })()
    },[])

    const handleOk = async () => {
        if(!amountOfRecords || !selectedAgent){
           message.warning('No agent selected!')
            return;
        }

        setConfirmLoading(true);
        let filter = filteredData.filter((data,index) => index < amountOfRecords);
        let user = agents.filter((user) => user.id === selectedAgent).pop();
        filter.forEach(selectedRecord => {
            allData.forEach(record => {
                if(selectedRecord.id == record.id)
                    record.user = user;
            })
            filteredData.forEach((record ,index,self)=> {
                if(selectedRecord.id == record.id){
                    self.splice(index,1);
                }
            })
        })
        await setFilteredData([...filteredData]);
        let data = { user : selectedAgent, records : filter};
        await assignInterviewsToAgent(data);
        await setInterviews(allData);

        setConfirmLoading(false);
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const sliderChange = (value) => {
        setAmountOfRecords(value);
    }

    return (
        <>
            <Button type="primary"
                    disabled={total <= 0}
                    onClick={() => {setVisible(true)}} >
                {buttonLabel}
            </Button>
            <Modal
                title={modalTitle}
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <Row>
                    <Col span={4}>
                        <Statistic value={total} title={t('Total')} />

                    </Col>
                    <Col span={4}>
                        <Statistic value={total - unassigned} title={t('Asigned')} />
                    </Col>
                    <Col span={4}>
                        <Statistic value={unassigned} title={t('Unassigned')} />
                    </Col>
                </Row>
                <Row>
                    <Text strong={true}>{t('Filtered records')} : </Text>
                    <Text>{(total > 0) ? filteredData.length : total}</Text>
                </Row>
                <Row>
                    <Text strong={true}>{t('Selected')} : </Text>
                    <Text>{amountOfRecords} / {(total > 0) ? filteredData.length : total}</Text>
                </Row>
                <Row>
                    <Slider
                        min={1}
                        max={total}
                        onChange={sliderChange}
                        style={{width:'100%'}}
                        //value={typeof inputValue === 'number' ? inputValue : 0}
                    />
                </Row>
                <Row>
                    <Text strong={true}>{t('Select agent')} :</Text>
                </Row>
                <Row>
                    <Radio.Group>
                        <List
                            style={{width:'100%'}}
                            size="small"
                            dataSource={agents}
                            renderItem={
                                (agent:any) =>
                                    <List.Item>
                                        <Radio  onChange={(element) => { setSelectedAgent(element.target.value); }} value={agent.id}/>
                                        {agent.name} {agent.lastname}
                                    </List.Item>
                            }
                        />
                    </Radio.Group>
                </Row>
            </Modal>
        </>
    );
})

