import React from 'react';
import {Layout, Menu} from "antd"
import {Link} from "react-router-dom";
import {useStores} from "../stores/StoresProvider";
import { useTranslation } from 'react-i18next';

export default () => {
    const {t} = useTranslation();
    const {loginStore} = useStores();
    const { role: currentUserRole }  = useStores().currentUserStore;
    return (
        <>
            <Layout.Header >
                <div className="logo"/>
                <Menu mode="horizontal" theme="dark" defaultSelectedKeys={(window.location.pathname === '/') ? ['header-menu-01'] : []}>
                    <Menu.Item key="header-menu-01"  ><Link to="/">{t('Home')}</Link></Menu.Item>
                    <Menu.Item key="header-menu-02" hidden={(currentUserRole !== 'QUALITY_CONTROL' ||currentUserRole !== 'QUALITY_CONTROL' )}><Link to="/campaigns">{t('Campaigns')}</Link></Menu.Item>
                    <Menu.Item key="header-menu-03" hidden={(currentUserRole !== 'QUALITY_CONTROL')}><Link to="/users">{t('Users')}</Link></Menu.Item>
                    <Menu.Item key="header-menu-04" style={{float:"right"}} onClick={loginStore.logout}>{t('Logout')}</Menu.Item>
                </Menu>

            </Layout.Header>
        </>
    );
}
