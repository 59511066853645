import React from "react";
import {useStores} from "../../stores/StoresProvider";
import {
    message,
    Popconfirm,
    Progress,
    Row,
    Col,
    Typography,
    Space,
    Steps
} from "antd";


export default (props) => {

    const {campaign} = props;
    const {campaignStore} = useStores();
    const {toggleCampaign} = campaignStore;
    const {Text,Title} = Typography;

    return(
        <>
            <div style={{padding:20}}>
                <Title level={5}>This campaign is active!</Title>
                <Text> You can monitor campaign progress from here or disable the campaign! </Text>
                <Row justify="center" style={{margin:20}}>
                    <Col className={"col-24"}>
                        <Steps current={(campaign.nAssignedUsers <= 0) ? 0 : (campaign.activeRecords > 0) ? 1: 2 } >
                            <Steps.Step title="Enabled" />
                            <Steps.Step title="In Progress" />
                            <Steps.Step title="Finished" />
                        </Steps>
                    </Col>
                </Row>
                <Row justify="center">
                    <Space direction="vertical" style={{textAlign:"center"}}>
                        <Progress percent={Math.floor((campaign.total - campaign.activeRecords) / campaign.total *100 )} type={"dashboard"} />
                        <Title level={5}>{`${campaign.nAssignedUsers || 'No'} agents assigned to campaign`}</Title>
                        <Title level={5}>{`Remaining records: ${campaign.activeRecords}`}</Title>
                    </Space>
                </Row>

                <div style={{ margin: 10, textAlign : "right"}}>
                    <Popconfirm
                        className="ant-btn ant-btn-dangerous"
                        title="Once you disable a campaign all data will be flushed. Unfinished progress will not be saved"
                        onConfirm={ async () => {
                            await toggleCampaign(campaign)
                            campaign.isActive = false;
                            message.success("Campaign disabled successfully!")
                        } }
                    >
                        Disable
                    </Popconfirm>
                </div>
            </div>
        </>
    );
}
