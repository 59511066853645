import {observable, action, toJS} from "mobx";
import merge from "lodash/merge"
import get from "lodash/get"

export default class FormStore {
    constructor() {
        this.getSumOfFields.bind(this);
    }

    @observable formValues = {};

    @action.bound updateFormValues = (value) => {
        this.formValues = merge(this.formValues, value);
    }

    @observable formSelectOptions = {};

    @action.bound setFieldSelectOptions = (field, values) =>
        this.formSelectOptions[field] = values

    getSumOfFields = (fields, path = undefined) => {
        console.log(toJS(this.formValues))
        const numOr0 = n => isNaN(n) ? 0 : n

        return fields.map(field => {
            return numOr0(get(this.formValues, ['factEquipment', field]))
        }).reduce((a, b) => numOr0(a) + numOr0(b))
    }
}
