import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useStores} from "../stores/StoresProvider";
/*
enum UserRoles {
    admin = 'ADMIN',
    quality = 'QUALITY_CONTROL',
    supervisor = 'SUPERVISOR',
    agent = 'AGENT'
}

allowedRoles,  Array<string>() / if (role in allowedRoles)
 */
export const PrivateRoute = ({component: Component, ...rest}) => {
    const {isLoggedIn} = useStores().loginStore;

    return (
        <Route {...rest}
               render={(props) => {
                   if (!isLoggedIn())
                       return <Redirect to={{pathname: '/login', state: {from: props.location}}}/>;

                   return <Component {...props} />;
               }}
        />
    );

};