import './App.css';
import React from "react";
import {Route, Switch} from "react-router-dom";
import Login from "./pages/Login";
import Template from "./pages/Template";


const App = () =>  {
    return (
        <Switch>
            <Route exact path="/login" component={Login} />
            <Route component={Template} />
        </Switch>
    );
};
export default App;
