import React from 'react';
import {Button, Table, Tag, Popconfirm,message} from 'antd'
import {useStores} from "../../stores/StoresProvider";
import {observer} from "mobx-react-lite";
import EditUserModal from "./EditModal";
import EditPasswordModal from "./EditPasswordModal";
import {DeleteOutlined} from "@ant-design/icons";

export default observer(() => {

    const {users,deleteUser,setUsers} = useStores().usersStore;
    const columns = [
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Full Name',
            key: 'fullname',
            render: record => {
                return <>{record.name} {record.lastname}</>;
            }
        },
        {
            title: 'Role',
            key: 'role',
            render: record => (
                <Tag color="blue">{record.role}</Tag>
            )
        },
        {
            title: 'Actions',
            key: '',
            render: record => (
                <>
                    <EditUserModal user={record}/>
                    <EditPasswordModal user={record}/>
                    <Popconfirm
                        title={`Delete user ${record.name}?`}
                        onConfirm={async () => {await deleteUserSuccess(record)}}
                    >
                        <Button type="primary" icon={<DeleteOutlined/>} danger/>
                    </Popconfirm>
                </>
            )
        }
    ];

    const deleteUserSuccess = async (record) => {
        await deleteUser(record);
        let source = users.filter((user) => {
            if(user.id !== record.id)
                return user;
        });
        setUsers(source);
        message.success({
            content: 'User deleted successfully'
        })
    }

    return (
        <Table
            columns={columns}
            dataSource={users}
            rowKey="id"
        />

    );


});

