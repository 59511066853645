import {api} from "../lib/api-cati";
import {observable, action, computed} from "mobx";

export default class InterviewsStore {

    @observable allData: Array<Interview> = new Array<Interview>();
    @observable filteredData: Array<Interview> = new Array<Interview>();
    @observable pagination = {};
    @observable filters = {} as any;
    @observable sorter = {};
    @observable interviews = {};
    @observable warehouseInterviews = {};
    //currentInterview:number;
    unassignedLabel = 'Not assigned'

    @observable recordStatusOptions: Array<string> = new Array<string>();


    @action.bound setPagination = (value) => this.pagination = value;

    @action.bound setFilters = (value) => this.filters = value;
    @action.bound resetFilters = () => this.filters = {};

    @action.bound setSorter = (value) => this.sorter = value;

    @action.bound setFilteredData = (value) => this.filteredData = value;
    @action.bound resetFilteredData = () => this.filteredData = this.allData;


    @action.bound setInterviews = (data) =>  this.allData = data;

    @action.bound fetchInterviews = async () => {
        let {data} = await api.get('interviews',{timeout:false}).json();
        this.setInterviews(data);
        this.filteredData = data;
    }

    @action.bound fetchDistinctRecordStatuses = async () => {
        if(this.recordStatusOptions.length <= 0){
            let {data} = await api.get('interviews/warehouse/options').json();
            this.recordStatusOptions  = data;
        }
    }

    @action fetchInterview = async (id) => {
        // TODO: think what to really do for cache
        // For now, use object.  MobX doesn't like sparse arrays, for performance reasons (gives "[mobx.array] Index out of bounds, is larger than 0")
        if (!this.interviews[id]) {
            const {data} = await api.get('interviews/' + id).json();
            this.interviews[id] = data;
        }
    }

    @action updateInterview = async (id, data) => {
        const response: any = await api.put('interviews/' + id,
            {
                json: data
            }).json();
        if (response.status === "success")
            this.interviews[id] = data
        return response
    }

    @action addNote = async (id, data) => {
        const response: any = await api.post('interviews/note/' + id,
            {
                json: data
            }).json();

        if (response.status === "success")
            return response.data;
        return false;
    }

    @action deleteNote = async (id,interview) => {
        const response: any = await api.delete(`interviews/note/${id}`).json();
        return (response.ok === true)
    }


    @action fetchWarehouseInterview = async (id) => {
        // TODO: think what to really do for cache
        // For now, use object.  MobX doesn't like sparse arrays, for performance reasons (gives "[mobx.array] Index out of bounds, is larger than 0")
        if (!this.warehouseInterviews[id]) {
            const {data} = await api.get('interviews/warehouse/' + id).json();
            this.warehouseInterviews[id] = data;
        }
    }

    @action assignInterviewsToAgent = async (data) => {
        let response = await api.patch('interviews/assignment',{json:data}).json();
        return (response === 'success');
    }

    @computed get countries() {
       let countries:string[] = [];
       if(this.allData){
           this.allData.forEach((interview) => {
               if(!countries.includes(interview.country))
                   countries.push(interview.country);
            });
       }
       return countries;
    }

    @computed get recordStatuses() {
        let statuses:string[] = [];
        if(this.allData) {
            this.allData.forEach((interview) => {
                if (!statuses.includes(interview.recordStatus))
                    statuses.push(interview.recordStatus);
            });
        }
        return statuses;
    }

    @computed get processStatuses() {
        let statuses:string[] = [];
        if(this.allData) {
            this.allData.forEach((interview) => {
                if (!statuses.includes(interview.processStatus))
                    statuses.push(interview.processStatus);
            });
        }
        return statuses;
    }

    @computed get agents() {
        let users:string[] = [];
        users.push(this.unassignedLabel);
        if(this.allData) {
            this.allData.forEach((interview) => {
                if(!interview.user)
                    return;
                if (!users.includes(interview.user.name))
                    users.push(interview.user.name);
            });
        }
        return users;
    }

    @computed get dates() {
        let dates:string[] = [];
        if(this.allData) {
            this.allData.forEach((interview) => {

                let d = new Date(interview.lastUpdated);
                let year = d.getFullYear().toString();
                if(!interview.lastUpdated)
                    year = 'Never Updated';
                if (!dates.includes(year))
                    dates.push(year);

                
            });
        }
        return dates;
    }

    @computed get priorities() {
        let priorities:number[] = [];
        if(this.allData) {
            this.allData.forEach((interview) => {
                if (!priorities.includes(interview.priority))
                    priorities.push(interview.priority);
            });
        }
        return priorities;
    }

    @computed get total() {
        return (this.filteredData) ? Object.keys(this.filteredData).length : 0 ;
    }

    @computed get unassigned() {
        let count = 0;
        for (let key in this.filteredData)
            if (this.filteredData[key].user === null) count++;
        return count;
    }

    @computed get updated() {
        let count = 0;
        for (let key in this.filteredData)
            if (this.filteredData[key].recordStatus === 'Updated') count++;
        return count;
    }

    @computed get unfinished() {
        let count = 0;
        for (let key in this.filteredData)
            if (this.filteredData[key].recordStatus !== 'Updated') count++;
        return count;
    }
}
