import {Button, Form, Input, message, Modal, Row} from "antd";
import React, {useRef, useState} from "react";
import {FormInstance} from "antd/es/form";
import {KeyOutlined} from "@ant-design/icons";
import {useStores} from "../../stores/StoresProvider";

export default ({user}) => {

    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const buttonLabel = '';
    const modalTitle = 'Edit password';
    const {editUserPassword} = useStores().usersStore;
    const ref = useRef<FormInstance|null>(null);

    const layout = {
        labelCol: {span: 12},
        wrapperCol: {span: 24},
    };

    const handleOk = async () => {
        if(!ref.current)
            return;
        setConfirmLoading(true);
        await ref.current.submit();
        setConfirmLoading(false);
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const onFinish = async(values) => {
        values.id = user.id;
        const response = await editUserPassword(values);
        if (response.ok) {
            message.success({
                content: `User ${values.username} created successfully`
            })
        } else {
            message.error({
                content: 'Error: '+ response.error
            })
        }
    };
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Button icon={<KeyOutlined/>} onClick={() => {
                setVisible(true)
            }}>{buttonLabel}</Button>
            <Modal
                title={modalTitle}
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <Row justify="center">
                    <Form
                        {...layout}
                        ref={ref}
                        name="editUserPassword"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={
                                [
                                    {required: true, message: 'Please input your password!'},
                                    {min: 8, message: 'Your password should be at least 8 characters long!'},
                                ]
                            }
                        >
                            <Input.Password/>
                        </Form.Item>

                        <Form.Item
                            label="Repeat password"
                            name="verification"
                            dependencies={['password']}
                            rules={[
                                {required: true, message: 'Please verify your password!'},
                                ({getFieldValue}) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('The two passwords that you entered do not match!');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>

                    </Form>
                </Row>
            </Modal>
        </>
    );
}