import React from "react";
import {PrivateRoute} from '../lib/PrivateRoute'
import {Layout, Spin} from "antd";
import {useStores} from "../stores/StoresProvider";
import {observer} from "mobx-react-lite";

import Campaigns from "./Campaigns";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Users from "./Users";
import Home from "./Home";
import Interview from "./Interview";

const Template = observer(() =>  {

    const {isLoading} = useStores().loaderStore;

    return (
        <>
            <Layout translate="no">
            <Header />
            <Layout.Content className="layout" style={{minHeight : "90vh",padding: 50}}>
                <Spin spinning={isLoading} size="large" > </Spin>
                <div style={{display:(isLoading) ? "none" : ""}}>
                    <PrivateRoute exact path="/" component={Home} />
                    <PrivateRoute exact path="/campaigns" component={Campaigns}  />
                    <PrivateRoute exact path="/users" component={Users}  />
                    <PrivateRoute exact path="/interviews/:id" component={Interview}  />
                </div>
            </Layout.Content>
            <Footer />
            </Layout>
        </>
    );
});

export default Template;
