import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {useStores} from "../stores/StoresProvider";

import InterviewList from '../components/home/InterviewsList';

export default observer(() => {
    const {fetchInterviews} = useStores().interviewsStore;
    const {load} = useStores().loaderStore;
    const pageTitle = 'Home - interview list';



    useEffect(() => {
        (async () => {
            await load(async () => {
                await fetchInterviews();
                document.title = pageTitle;
            })
        })()
    },[])


    //TODO:ROL CHECK
    return (
        <>
            <InterviewList  />
        </>
    )
})

