import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores/StoresProvider";
import { Row, Typography,Col } from "antd";

const InterviewsListHeader = ({itemsToDisplay}) => {

    const {interviewsStore} = useStores();
    const {allData} = interviewsStore;
    const unfilteredTotal = (allData) ? allData.length : 0;


   /*
    const percent = (value) => {
        return Math.round(value * 100 / total);
    };
    */

    return (<>
        <Row justify="end"> 
            <Col span={5} offset={19} >
                <Typography.Text type="secondary">
                    {`Displaying ${itemsToDisplay} of ${unfilteredTotal} interviews`}
                </Typography.Text>
            </Col>
        </Row>

    </>)
}

export default observer(InterviewsListHeader);
