import React from 'react';

export default  ({defaultPlace,latitude,longitude}) => {
    const googleAPIKey = 'AIzaSyAB2YE1MHyoBU39oAkEso8QhyqKeRb-Zyg';
    let coordinates = (!latitude || !longitude) ? defaultPlace : `${latitude},${longitude}`;
    return(
        <iframe
            src={`https://www.google.com/maps/embed/v1/place?key=${googleAPIKey}&q=${coordinates}` }
            allowFullScreen={true}
        >
        </iframe>
    );
}
