import React from 'react';
import {useStores} from "../../stores/StoresProvider";
import {Row, Col, Typography, Select, List, Button, Switch} from 'antd'
import WebsiteWidget from '../WebsiteWidget';
import Flag from "../CountryFlags";
import TelephoneList from "./InterviewTelephonesList";
import Notes from "./InterviewNotes";
import Map from "../EmbededGoogleMaps";
import { useTranslation } from 'react-i18next';

const InterviewDashboard = ({id}) => {
    const {interviews, warehouseInterviews} = useStores().interviewsStore;
    const {t} = useTranslation()


    if (!(interviews[id] && warehouseInterviews[id]))
        return (<></>);

    const getLatestData = (fieldData) => { return interviews[id][fieldData.category][fieldData.name] || warehouseInterviews[id][fieldData.category][fieldData.name]; }

    const {Title, Text} = Typography;

    return (
        <div style={{margin: "0"}}>
            <Title level={2} style={{display: 'inline'}}>{interviews[id].hospitalName}</Title>
            <Row>
                <Col span={6}>
                    <Flag
                        country={interviews[id].country}
                        size={'small'}
                        label={true}
                    />
                    <Row>
                        <Map
                            defaultPlace={interviews[id].country}
                            latitude={getLatestData({ category: 'generalInformation' , name : 'c0025_latitude'})}
                            longitude={getLatestData({ category: 'generalInformation' , name : 'c0026_longitude'})}
                        />
                    </Row>
                </Col>
                <Col span={6}>
                    <List size={"small"}>
                        <List.Item>
                            <Text strong={true}> {t('Last Updated')}: </Text>
                            {(interviews[id].lastUpdated) ? interviews[id].lastUpdated.slice(0, 10) : 'New'}
                        </List.Item>
                        <List.Item>
                            <Text strong={true}>{t('Status')} : </Text>{t(interviews[id].recordStatus)}
                        </List.Item>
                        <TelephoneList
                            telephones={getLatestData({ category: 'generalInformation' , name : 'c0020_telephones'})}
                            areaCode={getLatestData({ category: 'generalInformation' , name : 'c0019_area_code'})}
                            country={interviews[id].country}
                        />
                        <List.Item>
                            <WebsiteWidget url={getLatestData({ category: 'generalInformation' , name : 'c0029_website'}) || null} />
                        </List.Item>
                    </List>
                </Col>
                <Col span={12}>
                    <Notes
                        notes={interviews[id].notes}
                        interviewId={id}
                    />
                </Col>
            </Row>
        </div>
    );
};

//export default observer(InterviewDashboard);
export default InterviewDashboard;
